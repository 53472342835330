import React, {
  useRef,
  useState,
} from 'react';

import emailjs from '@emailjs/browser';
import { useLocation } from '@reach/router';

import Layout from '../components/layout/Layout';

function StudioDetail() {
  const form = useRef();
  const params = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    lname: "",
    phoneHome: "",
    email: "",
    loc: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.entries(userInfo).filter(
      ([key, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      alert(`Please complete all info`);
    } else {
      await emailjs.sendForm("service_uyai7ia", "template_phmirqe", form?.current, "qPnWu5SByn-ikFIuO");
      // await axios.post('https://api.vivaleisure.com.au/api/hub/gu_gymsales/', userInfo)
      //     .then(res => { }).catch(err => console.log(err));
      setLoading(true);
    }
  };

  return (
    <Layout>
      <div
        // id="studios-form-lead"
        className="member-page-container bg-[#EBEADF]"
      >
        <div className="membership-header">
          <h2 className="h4">FREE CLASS</h2>
        </div>
        <div className="w-[357px] bg-[#E0C3B2] mt-[65px] rounded-t px-[25px] py-[35px] border free-class-container mx-auto">
          <p className="text-[#693927] text-center mb-[45px] font-semibold">
            Fill the details below to book your FREE CLASS!
          </p>
          {!loading && (
            <form ref={form}>
              <input
                name="name"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, name: e.target.value })
                }
                className="input-form-fc bg-[#693927]"
                type="text"
                placeholder="First name"
              />
              <input
                name="lname"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lname: e.target.value })
                }
                className="input-form-fc"
                type="text"
                placeholder="Last name"
              />

              <input
                name="phoneHome"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, phoneHome: e.target.value })
                }
                className="input-form-fc"
                type="text"
                placeholder="Phone"
              />
              <input
                name="email"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
                className="input-form-fc"
                type="text"
                placeholder="Email"
              />
              {/* <input name='contactMethod' onChange={(e) => setUserInfo({ ...userInfo, contactMethod: e.target.value })} className='input-form-lead' type="text" placeholder='Contact_method_name' /> */}

              {/* <select name="time" className="dropdown" id="time">
                <option value="" disabled selected>
                  Complimentary Class Time
                </option>
                <option value="Morning">Morning</option>
                <option value="groundup-Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </select> */}
              {/* <input style={{ opacity: 0, height: 0 }} name='phoneMobile' onChange={(e) => setUserInfo({ ...userInfo, phoneMobile: e.target.value })} className='input-form-lead' type="text" placeholder='PHONE MOBILE' />
                            <input style={{ opacity: 0, height: 0 }} name='phoneWork' onChange={(e) => setUserInfo({ ...userInfo, phoneWork: e.target.value })} className='input-form-lead' type="text" placeholder='PHONE WORK' /> */}
              <select
                // style={{ opacity: 0, height: "1px" }}
                name="loc"
                value={userInfo.loc}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, loc: e.target.value })
                }
                className="input-form-fc dropdown"
                id="loc"
              >
                <option value="">Please Select a Studio</option>
                <option value="groundup-belconnen@email.gymsales.net">
                  Belconnen
                </option>
                <option value="groundup-yarralumla@email.gymsales.net">
                  Yarralumla
                </option>
                <option value="groundup-gungahlin@email.gymsales.net">
                  Gungahlin
                </option>
                <option value="groundup-alexandria@email.gymsales.net">
                  Alexandria
                </option>
                {/* <option value="groundup-braddon@email.gymsales.net">
                  Braddon
                </option> */}
              </select>
              <div
                id="form-button"
                className="cell text-center flex w-1/2 flex-1 margin-fix mt-2 justify-center"
              >
                <button
                  onClick={handleSubmit}
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                  name="submit"
                  id="submit"
                  className="button uppercase font-bold text-[20px] claim-button"
                >
                  Claim Now
                </button>
              </div>
            </form>
          )}
          {loading && (
            <span className="text-center text-[#693927] flex w-full">
             Thank you! We will be in touch to book your FREE CLASS soon.
            </span>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default StudioDetail;
