import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import emailjs from '@emailjs/browser';
import { useLocation } from '@reach/router';

import Layout from '../components/layout/Layout';

function StudioDetail() {
  const form = useRef();
  const params = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    lname: "",
    phoneHome: "",
    email: "",
    loc: "",
  });

  const formattedSegment = window.location.href
    .split("/")
    .pop()
    .split("-")
    .pop()
    .replace(/^\w/, (c) => c.toUpperCase());

  useEffect(() => {
    switch (params.pathname) {
      case "/sale-lead-form-gungahlin":
        setUserInfo({
          ...userInfo,
          loc: "groundup-gungahlin@email.gymsales.net",
        });
        break;
      case "/sale-lead-form-belconnen":
        setUserInfo({
          ...userInfo,
          loc: "groundup-belconnen@email.gymsales.net",
        });
        break;
      case "/sale-lead-form-yarralumla":
        setUserInfo({
          ...userInfo,
          loc: "groundup-yarralumla@email.gymsales.net",
        });
        break;
      case "/sale-lead-form-alexandria":
        setUserInfo({
          ...userInfo,
          loc: "groundup-alexandria@email.gymsales.net",
        });
        break;
      case "/sale-lead-form-braddon":
        setUserInfo({
          ...userInfo,
          loc: "groundup-braddon@email.gymsales.net",
        });
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.entries(userInfo).filter(
      ([key, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      alert(`Please complete all info`);
    } else {
      await emailjs.sendForm("service_uyai7ia", "template_phmirqe", form?.current, "qPnWu5SByn-ikFIuO");
      // await axios.post('https://api.vivaleisure.com.au/api/hub/gu_gymsales/', userInfo)
      //     .then(res => { }).catch(err => console.log(err));
      setLoading(true);
    }
  };

  return (
    <Layout>
      <div id="studios-form-lead" className="member-page-container">
        <div className="membership-header">
          <h2 className="h4">{formattedSegment} Enquiry</h2>
        </div>
        <div className="w-[357px] bg-[#050505] mt-[65px] rounded-t px-[25px] py-[35px] border border-white mx-auto">
          <p className="text-white text-center mb-[45px]">
            Enter your details and we will get in touch with you!
          </p>
          {!loading && (
            <form ref={form}>
              <input
                name="name"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, name: e.target.value })
                }
                className="input-form-lead"
                type="text"
                placeholder="First name"
              />
              <input
                name="lname"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lname: e.target.value })
                }
                className="input-form-lead"
                type="text"
                placeholder="Last name"
              />

              <input
                name="phoneHome"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, phoneHome: e.target.value })
                }
                className="input-form-lead"
                type="text"
                placeholder="Phone"
              />
              <input
                name="email"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
                className="input-form-lead"
                type="text"
                placeholder="Email"
              />
              {/* <input name='contactMethod' onChange={(e) => setUserInfo({ ...userInfo, contactMethod: e.target.value })} className='input-form-lead' type="text" placeholder='Contact_method_name' /> */}

              {/* <select name="time" className="dropdown" id="time">
                <option value="" disabled selected>
                  Complimentary Class Time
                </option>
                <option value="Morning">Morning</option>
                <option value="groundup-Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </select> */}
              {/* <input style={{ opacity: 0, height: 0 }} name='phoneMobile' onChange={(e) => setUserInfo({ ...userInfo, phoneMobile: e.target.value })} className='input-form-lead' type="text" placeholder='PHONE MOBILE' />
                            <input style={{ opacity: 0, height: 0 }} name='phoneWork' onChange={(e) => setUserInfo({ ...userInfo, phoneWork: e.target.value })} className='input-form-lead' type="text" placeholder='PHONE WORK' /> */}
              <select
                style={{ opacity: 0, height: "1px" }}
                name="loc"
                value={userInfo.loc}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, loc: e.target.value })
                }
                className="dropdown"
                id="loc"
              >
                <option value="groundup-belconnen@email.gymsales.net">
                  Belconnen
                </option>
                <option value="groundup-yarralumla@email.gymsales.net">
                  Yarralumla
                </option>
                <option value="groundup-gungahlin@email.gymsales.net">
                  Gungahlin
                </option>
                <option value="groundup-alexandria@email.gymsales.net">
                  Alexandria
                </option>
                <option value="groundup-braddon@email.gymsales.net">
                  Braddon
                </option>
              </select>
              <div
                id="form-button"
                className="cell text-center flex w-1/2 flex-1 margin-fix mt-2 justify-center"
              >
                <button
                  onClick={handleSubmit}
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                  name="submit"
                  id="submit"
                  className="button transparent uppercase font-bold text-[20px]"
                >
                  Please Contact Me
                </button>
              </div>
            </form>
          )}
          {loading && (
            <span className="text-center flex w-full">
              Thank you for registering your interest. Our team will be in touch
              shortly
            </span>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default StudioDetail;
